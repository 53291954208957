import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import db from '../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import '../styles.css';

function EditRates() {
  const { country } = useParams();
  const [airRate, setAirRate] = useState(144);
  const [seaRate, setSeaRate] = useState(95);
  const [transitRate, setTransitRate] = useState(125);
  const [exchangeRate, setExchangeRate] = useState(30);

  useEffect(() => {
    const fetchRates = async () => {
      const ratesRef = doc(db, 'rates', country);
      const docSnap = await getDoc(ratesRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAirRate(data.airRate || 144);
        if (country === 'korea') {
          setSeaRate(data.seaRate || 95);
          setTransitRate(data.transitRate || 125);
        }
        setExchangeRate(data.exchangeRate || 30);
      }
    };

    fetchRates();
  }, [country]);

  const handleSave = async () => {
    const ratesRef = doc(db, 'rates', country);
    const ratesData = {
      airRate,
      exchangeRate,
    };

    if (country === 'korea') {
      ratesData.seaRate = seaRate;
      ratesData.transitRate = transitRate;
    }

    await setDoc(ratesRef, ratesData);
    alert('匯率已保存');
  };

  return (
    <div>
      <h2>編輯匯率</h2>
      <p>
        空運公斤:
        <input
          type="number"
          value={airRate}
          onChange={(e) => setAirRate(Number(e.target.value))}
        />
      </p>
      {country === 'korea' && (
        <>
          <p>
            海運公斤:
            <input
              type="number"
              value={seaRate}
              onChange={(e) => setSeaRate(Number(e.target.value))}
            />
          </p>
          <p>
            轉機公斤:
            <input
              type="number"
              value={transitRate}
              onChange={(e) => setTransitRate(Number(e.target.value))}
            />
          </p>
        </>
      )}
      <p>
        匯率 ({country === 'korea' ? '韓幣轉台幣' : '泰銖轉台幣'}):
        <input
          type="number"
          value={exchangeRate}
          onChange={(e) => setExchangeRate(Number(e.target.value))}
        />
      </p>
      <button onClick={handleSave}>保存</button>
    </div>
  );
}

export default EditRates;
