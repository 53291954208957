import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBbDdBy_G9nLkZbREIef-11aFRqVp24u08",
  authDomain: "barcode-1b39e.firebaseapp.com",
  projectId: "barcode-1b39e",
  storageBucket: "barcode-1b39e.appspot.com",
  messagingSenderId: "548341187807",
  appId: "1:548341187807:web:c52a22ea58c038dd5cd56a"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
