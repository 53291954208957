import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import db from '../firebaseConfig';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import '../styles.css';

function ProductList() {
  const { country } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showAddProductPrompt, setShowAddProductPrompt] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (term = searchTerm) => {
    if (!term.trim()) {
      setFilteredProducts([]);
      return;
    }

    const lowerCaseTerm = term.toLowerCase();
    const productsCol = collection(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts');
    const productSnapshot = await getDocs(productsCol);
    const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // 模糊搜索并排序
    const filtered = productList.filter(p =>
      p.styleBarcodes.some(barcode => typeof barcode === 'string' && barcode.toLowerCase().includes(lowerCaseTerm)) ||
      (p.itemNumber && p.itemNumber.toLowerCase().includes(lowerCaseTerm)) ||
      (p.name && p.name.toLowerCase().includes(lowerCaseTerm)) ||
      (p.styles.some(style => style.toLowerCase().includes(lowerCaseTerm)))
    );

    // 按照品名排序
    filtered.sort((a, b) => a.name.localeCompare(b.name));

    setFilteredProducts(filtered);

    if (filtered.length === 0) {
      setShowAddProductPrompt(true);
    } else {
      setShowAddProductPrompt(false);
    }
  };

  const handleAddProduct = () => {
    navigate(`/add/${country}?barcode=${encodeURIComponent(searchTerm)}`);
  };

  const handleSelectProduct = (id, e) => {
    e.stopPropagation();
    setSelectedProducts(prevSelected => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(item => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm('你確定要刪除選擇的商品嗎？這個動作不能撤銷。')) {
      return;
    }
    const batch = selectedProducts.map(async (id) => {
      const productRef = doc(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts', id);
      await deleteDoc(productRef);
    });

    await Promise.all(batch);
    setSelectedProducts([]);
    window.location.reload();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="container">
      <h2>商品列表</h2>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={handleKeyDown}  // 监听按键事件
        placeholder="輸入商品條碼、料號或品名"
      />
      <button onClick={() => handleSearch()}>搜尋</button>
      {showAddProductPrompt && (
        <div>
          <p>未匹配到商品，是否新增商品？</p>
          <button onClick={handleAddProduct}>新增商品</button>
        </div>
      )}
      {filteredProducts.length > 0 && (
        <>
          <button onClick={handleDeleteSelected} disabled={selectedProducts.length === 0}>刪除選擇的商品</button>
          <div className="product-list">
            {filteredProducts.map(product => (
              <div
                key={product.id}
                className="product-card"
                onClick={() => navigate(`/view/${country}/${product.id}`)}
              >
                <div className="product-checkbox" onClick={(e) => e.stopPropagation()}>
                  <input
                    type="checkbox"
                    checked={selectedProducts.includes(product.id)}
                    onChange={(e) => handleSelectProduct(product.id, e)}
                  />
                </div>
                <img src={product.image} alt={product.name} className="product-image-small" />
                <div className="product-info">
                  <span>{product.name} ({product.styles.join(' / ')})</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ProductList;
