import React from 'react';
import '../styles.css'; // 確保路徑正確

function Home({ onCountrySelect }) {
  return (
    <div className="home-container">
      <h1>選擇國家</h1>
      <button onClick={() => onCountrySelect('korea')} className="country-button">
        🇰🇷 韓國
      </button>
      <button onClick={() => onCountrySelect('thailand')} className="country-button">
        🇹🇭 泰國
      </button>
    </div>
  );
}

export default Home;
