import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import db from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import '../styles.css';

function AddProduct() {
  const { country } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    image: '',
    name: '',
    itemNumber: '',
    livePrice: '',
    onlinePrice: '',
    remarks: '',
    costKoreanWon: '',
    costTWD: '',
    costTHB: '',
    weight: '',
    styles: [],
    styleBarcodes: [],
    costType: country === 'korea' ? 'KRW' : 'THB',
    newStyle: '',
    newStyleBarcode: new URLSearchParams(location.search).get('barcode') || ''
  });

  useEffect(() => {
    const barcode = new URLSearchParams(location.search).get('barcode');
    if (barcode) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        newStyleBarcode: barcode
      }));
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCostTypeChange = (e) => {
    const newCostType = e.target.value;
    setFormData({
      ...formData,
      costType: newCostType,
      costKoreanWon: newCostType === 'KRW' ? '' : formData.costKoreanWon,
      costTHB: newCostType === 'THB' ? '' : formData.costTHB,
      costTWD: newCostType === 'TWD' ? '' : formData.costTWD
    });
  };

  const handleSave = async () => {
    const styles = formData.styles.length > 0 ? formData.styles : [];
    const styleBarcodes = formData.styleBarcodes.length > 0 ? formData.styleBarcodes : [];
    
    // 如果使用者在條碼輸入框中有輸入條碼但沒有按新增樣式
    if (formData.newStyleBarcode.trim() !== '' && formData.newStyle.trim() === '') {
      styles.push('無樣式');
      styleBarcodes.push(formData.newStyleBarcode.trim());
    }

    const newProduct = {
      ...formData,
      styles,
      styleBarcodes,
      history: []
    };

    try {
      const docRef = await addDoc(collection(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts'), newProduct);
      console.log("Document written with ID: ", docRef.id);
      navigate(`/view/${country}/${docRef.id}`);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const addStyle = () => {
    const { newStyle, newStyleBarcode } = formData;
    if (newStyleBarcode.trim() !== '') {
      setFormData({
        ...formData,
        styles: [...formData.styles, newStyle],
        styleBarcodes: [...formData.styleBarcodes, newStyleBarcode],
        newStyle: '',
        newStyleBarcode: ''
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({
        ...formData,
        image: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="container product-edit">
      <h2>新增商品</h2>
      <div className="form-group">
        <label>名稱</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>料號</label>
        <input type="text" name="itemNumber" value={formData.itemNumber} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>直播價</label>
        <input type="text" name="livePrice" value={formData.livePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>線上價</label>
        <input type="text" name="onlinePrice" value={formData.onlinePrice} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>備註</label>
        <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>成本類型</label>
        <select name="costType" value={formData.costType} onChange={handleCostTypeChange}>
          <option value="TWD">台幣</option>
          {country === 'korea' && <option value="KRW">韓幣</option>}
          {country === 'thailand' && <option value="THB">泰銖</option>}
        </select>
      </div>
      {formData.costType === 'TWD' && (
        <div className="form-group">
          <label>成本 (台幣)</label>
          <input type="text" name="costTWD" value={formData.costTWD} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'KRW' && (
        <div className="form-group">
          <label>成本 (韓幣)</label>
          <input type="text" name="costKoreanWon" value={formData.costKoreanWon} onChange={handleChange} />
        </div>
      )}
      {formData.costType === 'THB' && (
        <div className="form-group">
          <label>成本 (泰銖)</label>
          <input type="text" name="costTHB" value={formData.costTHB} onChange={handleChange} />
        </div>
      )}
      <div className="form-group">
        <label>重量</label>
        <input type="text" name="weight" value={formData.weight} onChange={handleChange} />
      </div>
      <div className="form-group">
        <label>新增樣式</label>
        <input type="text" name="newStyle" value={formData.newStyle} onChange={handleChange} placeholder="樣式名稱" />
        <input type="text" name="newStyleBarcode" value={formData.newStyleBarcode} onChange={handleChange} placeholder="樣式條碼" />
        <button onClick={addStyle}>新增樣式</button>
      </div>
      <div className="form-group">
        <label>樣式列表</label>
        <ul>
          {formData.styles.map((style, index) => (
            <li key={index}>
              {style} ({formData.styleBarcodes[index]})
            </li>
          ))}
        </ul>
      </div>
      <div className="form-group">
        <label>商品圖片</label>
        <input type="file" onChange={handleImageUpload} />
        {formData.image && <img src={formData.image} alt="商品圖片" className="product-image-preview" />}
      </div>
      <button onClick={handleSave}>保存</button>
    </div>
  );
}

export default AddProduct;
