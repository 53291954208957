import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles.css';

function Login({ onLogin }) {
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '8888') {
      const deviceId = localStorage.getItem('deviceId') || navigator.userAgent + Math.random().toString(36).substring(2);
      localStorage.setItem('deviceId', deviceId);
      localStorage.setItem('lastLogin', new Date().toISOString());
      onLogin();
      navigate('/');
    } else {
      alert('密碼錯誤，請重新輸入。');
    }
  };

  return (
    <div className="login-container">
      <h2>登入</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="輸入密碼"
        />
        <button type="submit">登入</button>
      </form>
    </div>
  );
}

export default Login;
