import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import Home from './components/Home';
import ProductList from './components/ProductList';
import AddProduct from './components/AddProduct';
import BatchAddProduct from './components/BatchAddProduct';
import EditProduct from './components/EditProduct';
import ViewProduct from './components/ViewProduct';
import EditRates from './components/EditRates';
import Login from './components/Login';
import './styles.css';

function AppContent() {
  const [country, setCountry] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const lastLogin = localStorage.getItem('lastLogin');
    const storedDeviceId = localStorage.getItem('deviceId');
    const currentDeviceId = navigator.userAgent;

    if (lastLogin && storedDeviceId && storedDeviceId.startsWith(currentDeviceId)) {
      const lastLoginDate = new Date(lastLogin);
      const currentDate = new Date();

      if (lastLoginDate.toDateString() === currentDate.toDateString()) {
        setIsLoggedIn(true);
      }
    }
  }, []);

  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
    navigate(`/products/${selectedCountry}`);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="App">
      {!country ? (
        <Home onCountrySelect={handleCountrySelect} />
      ) : (
        <>
          <nav>
            <button onClick={() => setCountry(null)} className="switch-country-button">切換國家</button>
            <button onClick={() => setIsCollapsed(!isCollapsed)} className="menu-button">
              {isCollapsed ? "展開選單" : "收合選單"}
            </button>
            {!isCollapsed && (
              <ul>
                <li><Link to={`/products/${country}`}>商品列表</Link></li>
                <li><Link to={`/add/${country}`}>新增商品</Link></li>
                <li><Link to={`/batch-add/${country}`}>批次新增商品</Link></li>
                <li><Link to={`/edit-rates/${country}`}>編輯匯率運費</Link></li>
              </ul>
            )}
          </nav>
          <Routes>
            <Route path="/products/:country" element={<ProductList />} />
            <Route path="/add/:country" element={<AddProduct />} />
            <Route path="/batch-add/:country" element={<BatchAddProduct />} />
            <Route path="/edit-rates/:country" element={<EditRates />} />
            <Route path="/view/:country/:id" element={<ViewProduct />} />
            <Route path="/edit/:country/:id" element={<EditProduct />} />
          </Routes>
        </>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
