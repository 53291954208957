import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs, setDoc, doc } from 'firebase/firestore';
import db from '../firebaseConfig';
import '../styles.css';

function BatchAddProduct() {
  const { country } = useParams();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      const productsRef = collection(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts');

      for (const item of json) {
        const itemNumber = String(item.料號 || '');
        const style = item.樣式 ? item.樣式 : '無樣式';
        const styleBarcode = String(item.樣式條碼 || '');

        // 查找條碼是否存在於現有商品中
        const existingProductByBarcode = await getDocs(query(productsRef, where('styleBarcodes', 'array-contains', styleBarcode)));

        // 查找料號是否存在於現有商品中
        const existingProductByItemNumber = await getDocs(query(productsRef, where('itemNumber', '==', itemNumber)));

        let existingProduct = null;
        if (!existingProductByBarcode.empty) {
          existingProduct = existingProductByBarcode.docs[0];
        } else if (!existingProductByItemNumber.empty) {
          existingProduct = existingProductByItemNumber.docs[0];
        }

        if (existingProduct) {
          const existingData = existingProduct.data();
          const historyEntry = {
            date: new Date().toLocaleDateString(),
            costKoreanWon: existingData.costKoreanWon,
            costTWD: existingData.costTWD,
            costTHB: existingData.costTHB,
            livePrice: existingData.livePrice,
            onlinePrice: existingData.onlinePrice,
            remarks: existingData.remarks
          };

          // 更新樣式
          const updatedStyles = existingData.styles.map((s, i) => 
            existingData.styleBarcodes[i] === styleBarcode ? style : s
          );
          const updatedStyleBarcodes = existingData.styleBarcodes.map((b, i) =>
            existingData.styleBarcodes[i] === styleBarcode ? styleBarcode : b
          );

          // 確保新樣式會被加入
          if (!updatedStyleBarcodes.includes(styleBarcode)) {
            updatedStyles.push(style);
            updatedStyleBarcodes.push(styleBarcode);
          }

          const updatedData = {
            ...existingData,
            history: [...(existingData.history || []), historyEntry],
            image: item.圖片 || existingData.image,
            name: item.名稱 || existingData.name,
            livePrice: item.直播價 || existingData.livePrice,
            onlinePrice: item.線上價 || existingData.onlinePrice,
            remarks: item.備註 || existingData.remarks,
            costTWD: item.成本台幣 || existingData.costTWD,
            costKoreanWon: item.成本韓幣 || existingData.costKoreanWon,
            costTHB: item.成本泰銖 || existingData.costTHB,
            weight: item.重量 || existingData.weight,
            styles: updatedStyles,
            styleBarcodes: updatedStyleBarcodes
          };

          // 如果商品料號為空，更新為新料號
          if (!existingData.itemNumber && itemNumber) {
            updatedData.itemNumber = itemNumber;
          }

          await setDoc(doc(productsRef, existingProduct.id), updatedData);
        } else {
          const newProduct = {
            image: item.圖片 || '',
            name: item.名稱 || '',
            itemNumber: itemNumber,
            livePrice: item.直播價 || '',
            onlinePrice: item.線上價 || '',
            remarks: item.備註 || '',
            costKoreanWon: item.成本韓幣 || '',
            costTWD: item.成本台幣 || '',
            costTHB: item.成本泰銖 || '',
            weight: item.重量 || '',
            styles: [style],  // 過濾掉空的樣式
            styleBarcodes: [styleBarcode],
            history: []
          };

          const newProductRef = doc(productsRef);
          await setDoc(newProductRef, newProduct);
        }
      }

      window.location.reload();
    };

    reader.readAsArrayBuffer(file);
  };

  const handleExport = async () => {
    const productsRef = collection(db, country === 'korea' ? 'koreanProducts' : 'thaiProducts');
    const snapshot = await getDocs(productsRef);
    const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const formattedProducts = [];

    products.forEach(product => {
      product.styles.forEach((style, index) => {
        formattedProducts.push({
          圖片: product.image,
          名稱: product.name,
          料號: product.itemNumber,
          直播價: product.livePrice,
          線上價: product.onlinePrice,
          備註: product.remarks,
          成本韓幣: product.costKoreanWon,
          成本台幣: product.costTWD,
          成本泰銖: product.costTHB,
          重量: product.weight,
          樣式: style,
          樣式條碼: product.styleBarcodes[index]
        });
      });
    });

    const ws = XLSX.utils.json_to_sheet(formattedProducts);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Products');
    XLSX.writeFile(wb, `商品匯出_${new Date().toLocaleString().replace(/[:]/g, '-')}.xlsx`);
  };

  const handleDownloadTemplate = () => {
    const template = [{
      圖片: '',
      名稱: '',
      料號: '',
      直播價: '',
      線上價: '',
      備註: '',
      成本韓幣: '',
      成本台幣: '',
      成本泰銖: '',
      重量: '',
      樣式: '',
      樣式條碼: ''
    }];
    const ws = XLSX.utils.json_to_sheet(template);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
    XLSX.writeFile(wb, '批次新增商品範本.xlsx');
  };

  return (
    <div className="container">
      <h2>批次新增商品</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>上傳</button>
      <button onClick={handleExport}>匯出所有商品資料</button>
      <button onClick={handleDownloadTemplate}>下載範本</button>
    </div>
  );
}

export default BatchAddProduct;
